/* courier-prime-regular - latin */
@font-face {
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/courier/courier-prime-v2-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/courier/courier-prime-v2-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/courier/courier-prime-v2-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/courier/courier-prime-v2-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/courier/courier-prime-v2-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/courier/courier-prime-v2-latin-regular.svg#CourierPrime') format('svg'); /* Legacy iOS */
}
/* courier-prime-700 - latin */
@font-face {
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/courier/courier-prime-v2-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/courier/courier-prime-v2-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/courier/courier-prime-v2-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/courier/courier-prime-v2-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/courier/courier-prime-v2-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/courier/courier-prime-v2-latin-700.svg#CourierPrime') format('svg'); /* Legacy iOS */
}
/* courier-prime-700italic - latin */
@font-face {
  font-family: 'Courier Prime';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/fonts/courier/courier-prime-v2-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/courier/courier-prime-v2-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/courier/courier-prime-v2-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/courier/courier-prime-v2-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/courier/courier-prime-v2-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/courier/courier-prime-v2-latin-700italic.svg#CourierPrime') format('svg'); /* Legacy iOS */
}




.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#polygon-canvas {
  position:fixed !important;
  left:0;
  top:0;

}